/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Wrap,
  DateTimeTitle,
  ColWraper,
  MidleDateTime,
  WearInfomation,
  TitleInfo,
  WrapperSpin,
  WrapperLabel,
} from "./styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row, Select, Form, Tabs, Spin, Tooltip } from "antd";
import FormDatePicker from "Components/Form/FormDatePicker";
import ListCourses from "Components/List";
import BreadcrumbComponent from "Components/Breadcrumb";
import FormInput from "Components/Form/FormInput";
import ModalInforStudent from "./ModalInforStudent";
import { IS_EXPORT_STEP1, errorForm, RESPONSE_STATUS, SUBJECT_TITLE, DEFAULT_SCIENCE } from "Constants";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import actions from "Store/Actions";
import { regex } from "Utils/regex";
import Notification from "Components/Notification";
import moment from "moment";
import { FailureBtn } from "Components/Button";
import TableComponent from "Components/Table";
import { resetChecked } from "Utils/resetField";
import dayjs from "dayjs";
import ListCourseChecked from "./ListCourseChecked";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ModalCharacterStudent from "./ModalCharacterStudent";
import { setLocalStorage, STORAGE } from 'Utils/storage';
import LoadingModal from "Pages/LoadingModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import IconBack from "Components/IconBack";
import { textNoData } from "Utils/data-default";
import { convertAutotickSubject, convertSubjectContentToKeyValueArray, convertValueTickSubjectToArrEmpty, flattenArrayFromObject } from "Utils/convertValue";
import ModalWeeklyCourse from "./ModalWeeklyCourse";

const {
  getSubjectContent,
  getMaxScore,
  // generateFirstRank,
  getCompulsorySubjectsV2,
  // getInforStudentSaved,
  getListUniversity,
  getFullUniversityInformation,
  getAspirationalId,
  backAuthen,
  resetInfomation,
  listDataUniversity,
  getCurriculumDetail,
  resetCurriculum,
  getStudentInformation
} = actions;
const Home = () => {
  const [t] = useTranslation('student');
  const validationSchema = yup
  .object({
    studentID: yup
      .string()
      .typeError(errorForm.IS_STRING)
      .required(t(("message.id_is_required")))
      .matches(regex.interger8digit , t("id_field_incorrect_format")),
    startTime: yup.string().required(t(("message.field_is_required"))),
    endTime: yup.string().required(t(("message.field_is_required"))),
    socialScience: yup
      .string()
      .typeError(t(("student:message.field_is_required_science")))
      .required(t(("student:message.field_is_required_science"))),
    universityNameNew: yup
      .string()
      .required(t(("student:message.field_is_required_uni"))),
    facultyNameNew: yup
      .string()
      .required(t(("student:message.field_is_required_depart"))),
    majorNameNew: yup
      .string()
      .required(t(("student:message.field_is_required_major"))),
    scheduleMethodNew: yup
      .string()
      .required(t(("student:message.field_is_required_method"))),
  })
  .required();
  
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    listSubjectContentCommon,
    listSubjectContentPersonal,
    maxScore,
    // listScheduleMethod,
    studentID,
    inforStudent,
    studentIDSaved,
    contentSubjectIDSaved,
    loadingstudentIDSaved,
    listUniversity,
    testLoading,
    currentStudentInformation,
    dataUniversitys,
    listCurriculum,
    listSubjectContentCommonFirst,
    listSubjectContentPersonalFirst
  } = useSelector((state) => state.student);
  window.history.replaceState({}, document.title)

  
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const [visibleModal, setVisibleModal] = useState(false);
  const [character, setCharacter] = useState(false);
  const [showWeeklyCourse, setShowWeeklyCourse]= useState(false);
  const [loading, setLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false)
  const [loadingGetFullUni, setLoadingGetFullUni] = useState(false)
  const [selectedContent, setSelectedContent] = useState(SUBJECT_TITLE);
  const [selectedContentPerson, setSelectedContentPerson] = useState(SUBJECT_TITLE);
  const [indexMathChecked, setIndexMathChecked] = useState(-1);
  const [university, setUniversity] = useState()
  const [dataUniversity, setDataUniversity] = useState()
  const [listFaculty, setListFaculty] = useState([])
  const [faculty, setFaculty] = useState()
  const [listDepartmentMajor, setListDepartmentMajor] = useState([])
  const [departmentMajor, setDepartmentMajor] = useState()
  const [listScheduleMethod, setListScheduleMethod] = useState([]) 
  const [scheduleMethod, setScheduleMethod] = useState()
  const [listBranchName, setListBranchName] = useState([])
  const [branchName, setBranchName] = useState()
  const [weeklyCourse, setWeeklyCourse] = useState(10)
  const [maxWeeklyCourse] = useState(30)
  const [listScience, setListScience] = useState([])
  const [science, setScience] = useState()
  const [aspirationId, setAspirationId] = useState()
  const [dataSelected, setDataSelected] = useState({});
  const [dataCharacter, setDataCharacter] = useState()
  const [ratioTimeunitSubject, setRatioTimeunitSubject] = useState()
  const [optionTimeLimit, setOptionTimeLimit] = useState()
  const [apiFailed, setApiFailed] = useState(true)
  const [checkGenerated,setCheckGenerated] = useState(false);
  const [idStudent, setIdStudent] = useState(true);
  const [keyTab, setKeyTab] = useState(1);
  const [time, setTime] = useState({
    startTime: moment(new Date()),
    endTime: moment(new Date()).add(1, "years"),
  });
  const dispatch = useDispatch();
  const {
		t: trans,
	} = useTranslation(['student']);
  const { handleSubmit, setValue, clearErrors, watch ,formState: { errors }} = form;
  const scrollToForm = useRef();
  useEffect(() => {
    if (!_.isEmpty(errors)) {
      scrollToForm.current.scrollIntoView();
    }
  // eslint-disable-next-line no-use-before-define
  }, [errors]);
  const studentIDInput = watch("studentID");
  const socialScience = watch('socialScience');
  const getListSubjectTick = useMemo(() => {
    const keys1 = Object.keys(selectedContent).filter(key => {
      if (key !== "社会" && key !== "理科") {
        const values = selectedContent[key];
        return values?.length;
      } else {
        // Nếu là "社会" hoặc "理科", kiểm tra các value
        const values = selectedContent[key] ;
        const resultFlatMap = Object.values(values).flatMap(value => value)
        return resultFlatMap.length > 0;
      };
    });
      const keys2 = Object.keys(selectedContentPerson).filter(key => {
        if (key !== "社会" && key !== "理科") {
          const values = selectedContentPerson[key];
          return values?.length;
        } else {
          // Nếu là "社会" hoặc "理科", kiểm tra các value
          const values = selectedContentPerson[key];
          const resultFlatMap = Object.values(values).flatMap(value => value)
          
          return resultFlatMap.length > 0;
        };
      });
    const uniqueKeys = Array.from(new Set([...keys1, ...keys2]));
    return  uniqueKeys
  }, [selectedContent, selectedContentPerson])
  const onToggle = () => {
    setVisibleModal(!visibleModal);
  };
  useEffect(() => {
    if (!listCurriculum.length && params.ID && params.curriculum !== 'create') {
      history.push(`/student/${params.ID}`);
    }
  }, [history, params.ID, listCurriculum]);
  useEffect(() => {
    if (location.search) {
      const token = location.search.split("=")[1]
      setLocalStorage(STORAGE.USER_TOKEN, token)
    }
  }, [])

  const onGetInformationStudent = (id, curriculum) => {
    setLoading(true)
    dispatch(
      getStudentInformation(id, (action, res) => {
        const { data } = res;
        if(res.status !== RESPONSE_STATUS.SUCCESS) {
          setLoading(false)
          setApiFailed(true)
          setIdStudent(true)
          return;
        }
        if (curriculum !== "create") {
          setIsGenerated(true)
          setUniversity(null)
          setFaculty(null)
          setDepartmentMajor(null)
          setScheduleMethod(null)
          setBranchName(null)
          setValue("universityNameNew", undefined);
          setValue("facultyNameNew", undefined);
          setValue("majorNameNew", undefined);
          setValue("scheduleMethodNew", undefined);
          setListFaculty([])
          setListDepartmentMajor([])
          setListBranchName([])
          setListScheduleMethod([])
          setApiFailed(false)
          setIdStudent(false)
        } else {
          setApiFailed(false)
          setIdStudent(false)
          if (!university) {
            if ((listUniversity.some(university => university.value === data.universityName)) && _.isEmpty(currentStudentInformation)) {
              handleSearchUniversity(data.universityName)
            }
          }
          setIsGenerated(false)
        }

        const { studentClass, aspirationalID , universityName , departmentName , majorName } = data
        let newDataCurrent = currentStudentInformation
        newDataCurrent.studentClass = studentClass 
        newDataCurrent.aspirationalID = aspirationalID
        newDataCurrent.universityName = universityName || currentStudentInformation.universityName
        newDataCurrent.departmentName = departmentName || currentStudentInformation.departmentName
        newDataCurrent.majorName = majorName || currentStudentInformation.majorName
        
        let dataClone = currentStudentInformation.hasOwnProperty('dataCharacter') ? newDataCurrent : data

          if (curriculum === "create") {
            setApiFailed(false)
            setCheckGenerated(false)
            for (const [key, value] of Object.entries(dataClone)) {
              //response scheduleMethod is array
              if (key !== "scheduleMethod" || key !== "socialScience") {
                if (key === "socialScience") {
                  setTimeout(()=>{
                    setValue("socialScience", science);
                  },0)
                } else {
                  setTimeout(()=>{
                    setValue(key, value);
                  },0)
                }
              }
            }
            if (_.isEmpty(currentStudentInformation)){
              setTime({
                startTime: moment(new Date()),
                endTime: moment(new Date()).add(1, "years"),
              });
            }
          }
          else {
            setApiFailed(false)
            setCheckGenerated(true)
            dispatch(getCurriculumDetail({
              studentID: id,
              curriculumID: curriculum
            }, (action, infor) => {
              const { data } = infor;
              
             if (!_.isEmpty(data) && !data.generatedClassScheduleID) {
              const {endTime, startTime, aspirational, version} = data
              for (const [key, value] of Object.entries(aspirational)) {
                setTimeout(()=>{
                  setValue(key, value);
                },0)
              }
              for (const [key, value] of Object.entries(data)) {
                if (key === "scienceType") {
                  setTimeout(()=>{
                  setValue(key, value);
                },0)
                }
              }
              let objData = {
                aspirational: aspirational.aspirationalID,
                branch: aspirational.branchAspiration === null ? "" : aspirational.branchAspiration,
                version: version
              }
              dispatch(getMaxScore(objData));
              setTime({
                startTime: dayjs(startTime),
                endTime: dayjs(endTime),
              });
              }
              if (infor.status !== RESPONSE_STATUS.SUCCESS && infor.message_code === 24) {
                dispatch(
                  resetCurriculum({})
                )
                history.push({
                  pathname: `/student/${id}`,
                })
              }
            }))
          }
        setLoading(false)
        resetChecked()
        setIndexMathChecked(-1);
        // setValue("selectedContent", {})
        // setSelectedContent({})
      })
    );
  };

  
  const dataGeneralExam = useMemo(() => {
    return !_.isEmpty(maxScore) ? maxScore[`共通テスト`] : [];
  }, [maxScore]);
  const dataIndividualExam = useMemo(() => {
    return !_.isEmpty(maxScore) ? maxScore[`二次・個別`] : [];
  }, [maxScore]);
  const onSubmit = (info) => {
    //check input not checked anything 
    if (!flattenArrayFromObject(selectedContent).length && !flattenArrayFromObject(selectedContentPerson).length) {
      
      Notification.error(trans("student:choose_atleast_one_subject"), 5000);
      return
    }
    setDataSelected(info);
    onToggleCharacter();
  };

  const getListUniversityDefault = (universityName) => {
    dispatch(
      getListUniversity(universityName, (action, res) => {
      })
    )
  }

  const getDetailInformation = (universityName) => {

    if(universityName !== "" && !!universityName ) {
      setLoadingGetFullUni(true)
      dispatch(
        getFullUniversityInformation({
          universityName
        }, (action, res) => {
          if(res.status === RESPONSE_STATUS.SUCCESS) {
            setDataUniversity(res.data)
            dispatch(listDataUniversity({
              dataUniversitys : res.data
            }))
            let keys = Object.keys(res.data[`${universityName}`]);
            const newKeys = keys.filter((faculty) => {
              return faculty !== "SCIENCE_TYPE"
            })
            const dataFaculty = newKeys.map((faculty) => (
              { value: faculty, label: faculty }
            ))

            let listScience = Object.values(res.data[`${universityName}`][ "SCIENCE_TYPE"])
            const dataScience = listScience.map((science) => (
              { value: science, label: science }
            ))
            if (dataScience.length === 1) {
              handleScience(dataScience[0].value)
              setListScience(dataScience)
            } else if (dataScience.length === 0) {
              setListScience(DEFAULT_SCIENCE)
            } else {
              setListScience(dataScience)
            }
            setListFaculty(dataFaculty)
            setLoadingGetFullUni(false)
          } else {
            setLoadingGetFullUni(false)
          }
        })
      )
    }
  }

  useEffect(() => {
    if (university && faculty && departmentMajor && scheduleMethod) {
      dispatch(
        getAspirationalId({
          universityName: university,
          departmentName: faculty,
          majorName: departmentMajor,
          scheduleMethod: scheduleMethod,
        }, (action, res) => {
          if(res.status === RESPONSE_STATUS.SUCCESS){
            setAspirationId(res.data)
          }
        })
      )
    } else {
      //change
      setSelectedContent(SUBJECT_TITLE)
      setSelectedContentPerson(SUBJECT_TITLE)
      setAspirationId(undefined)
    }
  }, [university, faculty, departmentMajor, scheduleMethod])

  useEffect(() => {
    if (_.isEmpty(currentStudentInformation.selectedContent)) {
      if (science && aspirationId) {
        dispatch(
          getCompulsorySubjectsV2(
            {
              aspiration_id: aspirationId,
              science_type: science
            }, (action, res) => {
              if (res.status === RESPONSE_STATUS.SUCCESS) {
                setSelectedContentPerson(convertAutotickSubject(res?.data["specific_exam"]))
                setSelectedContent(convertAutotickSubject(res?.data["general_exam"]))
                // setSelectedContentPerson(arrAutotickContentPersonal)
                // setSelectedContent(arrAutotickContentCommon)
              }
            }
          )
        )
      } else {
        setSelectedContent(SUBJECT_TITLE)
        setSelectedContentPerson(SUBJECT_TITLE)
      }
    }
  }, [science, aspirationId])

  useEffect(() => {
    if (listBranchName.length > 0) {
      if (branchName && aspirationId) {
        dispatch(
          getMaxScore(
            {
              aspirational: aspirationId,
              branch: branchName
            }
          )
        )
      }
    } else {
      if (branchName === null && aspirationId) {
        dispatch(
          getMaxScore(
            {
              aspirational: aspirationId,
              branch: ""
            }
          )
        )
      }
    }
  }, [branchName, aspirationId])

  useEffect(() => {
    if (params.curriculum === 'create') {
      getListUniversityDefault("")
    }
  }, [])
  
  useEffect(() => {
    if (!_.isEmpty(dataUniversitys) && !_.isEmpty(currentStudentInformation) && currentStudentInformation.hasOwnProperty('dataCharacter')) {
      setDataUniversity(dataUniversitys)
      let keys = Object.keys(dataUniversitys?.[`${currentStudentInformation?.universityNameNew || currentStudentInformation?.universityName}`]);
            const newKeys = keys.filter((faculty) => {
              return faculty !== "SCIENCE_TYPE"
            })
            const dataFaculty = newKeys.map((faculty) => (
              { value: faculty, label: faculty }
            ))

            let listScience = Object.values(dataUniversitys[`${currentStudentInformation?.universityNameNew || currentStudentInformation?.universityName}`][ "SCIENCE_TYPE"])
            const dataScience = listScience.map((science) => (
              { value: science, label: science }
            ))
            if (dataScience.length === 1) {
              handleScience(dataScience[0].value)
              setListScience(dataScience)
            } else if (dataScience.length === 0) {
              setListScience(DEFAULT_SCIENCE)
            } else {
              setListScience(dataScience)
            }
            setListFaculty(dataFaculty)
    }
    else {
      getDetailInformation(university)
    }
  }, [university])

  useEffect(() => {
    if (_.isEmpty(currentStudentInformation)) { 
      if (listFaculty.some(faculty => faculty.value === `${inforStudent.departmentName}`)) {
        handleSearchFaculty(`${inforStudent.departmentName}`)
      }
      if (listFaculty.some(faculty => faculty.value === `${inforStudent.departmentName}部`)) {
        handleSearchFaculty(`${inforStudent.departmentName}部`)
      }
      if (listFaculty.some(faculty => faculty.value === `${inforStudent.departmentName}学部`)) {
        handleSearchFaculty(`${inforStudent.departmentName}学部`)
      }
      if (listFaculty.length === 1) {
        handleSearchFaculty(listFaculty[0].value)
      } 
    }
  }, [listFaculty])
  useEffect( () => {
    if (_.isEmpty(currentStudentInformation)) { 
      if (listDepartmentMajor.some(major => major.value === `${inforStudent.majorName}`)) {
        handleSearchMajor(`${inforStudent.majorName}`)
      }
      if (listDepartmentMajor.some(major => major.value === `${inforStudent.majorName}科`)) {
        handleSearchMajor(`${inforStudent.majorName}科`)
      }
      if (listDepartmentMajor.some(major => major.value === `${inforStudent.majorName}学科`)) {
        handleSearchMajor(`${inforStudent.majorName}学科`)
      }
      if (listDepartmentMajor.length === 1) {
        handleSearchMajor(listDepartmentMajor[0].value)
      } 
    }
  }, [listDepartmentMajor])

  useEffect(() => {
    if (listScheduleMethod.length === 1) {
      handleSearchMethod(listScheduleMethod[0].value)
    } 
  }, [listScheduleMethod])

  useEffect(() => {
    if (listBranchName.length !== 0 && !currentStudentInformation.branchAspiration) {
      handleSearchBranchName(listBranchName[0].value)
    } 
  }, [listBranchName])

  useEffect(() => {
    setValue("startTime", moment(new Date()).format("YYYY/MM/DD"))
    setValue("endTime", moment(new Date()).add(1,'years').format("YYYY/MM/DD"))
    if (_.isEmpty(listSubjectContentCommon) && _.isEmpty(listSubjectContentPersonal) ) {
      dispatch(getSubjectContent());
    }
    if(location.pathname === "/") {
      history.push({
        pathname: `/error`,
        state: {
          code_error: '404'
        }
      })
      dispatch(backAuthen());
    }
  }, []);

  useEffect(() => {
    if (params.hasOwnProperty("ID")) {
      setValue("studentID", params.ID);
      if (listUniversity.length > 0 || params.curriculum !== 'create') {
        onGetInformationStudent(params.ID, params.curriculum === 'create' ? 'create' : listCurriculum?.[params.curriculum-1]?.curriculumID)
      }
    }
  }, [params, listUniversity]);
  useEffect(() => {
    if (!_.isEmpty(currentStudentInformation && currentStudentInformation.universityNameNew && currentStudentInformation?.facultyNameNew && currentStudentInformation?.majorNameNew)) {
        setUniversity(currentStudentInformation?.universityNameNew)
        setFaculty(currentStudentInformation?.facultyNameNew)
        setDepartmentMajor(currentStudentInformation?.majorNameNew)
        setScheduleMethod(currentStudentInformation?.scheduleMethodNew)
        setScience(currentStudentInformation?.socialScience)
        setBranchName(currentStudentInformation?.branchAspiration)
        setWeeklyCourse(currentStudentInformation?.limitPeriod)
        setValue("branchAspiration", currentStudentInformation?.branchAspiration);
        setValue("universityNameNew", currentStudentInformation?.universityNameNew);
        setValue("facultyNameNew", currentStudentInformation?.facultyNameNew);
        setValue("majorNameNew", currentStudentInformation?.majorNameNew);
        setValue("scheduleMethodNew", currentStudentInformation?.scheduleMethodNew);
        setValue("socialScience", currentStudentInformation?.socialScience);
        setValue("startTime", currentStudentInformation?.startTime);
        setValue("endTime", currentStudentInformation?.endTime);
        setTime({
          startTime: moment(currentStudentInformation?.startTime),
          endTime: moment(currentStudentInformation?.endTime),
        })
        setValue("limitPeriod", currentStudentInformation?.limitPeriod);
      
        if (dataUniversity) {
          let keysDepartmentMarjor = Object.keys(dataUniversity?.[`${university}`]?.[`${faculty}`]);
          const departmentMajorList = keysDepartmentMarjor.map((major) => (
            { value: major, label: major }
          ))
          setListDepartmentMajor(departmentMajorList)
      }
      if (dataUniversity) {
        let keysScheduleMethod = Object.keys(dataUniversity?.[`${university}`]?.[`${faculty}`]?.[`${departmentMajor}`]);
          const scheduleMethodList = keysScheduleMethod.map((method) => (
            { value: method, label: method }
          ))
          setListScheduleMethod(scheduleMethodList)
      }
      if (dataUniversity) {
        let keysBranchName = Object.values(dataUniversity[`${university}`][`${faculty}`][`${departmentMajor}`][`${scheduleMethod}`]);
        const branchNameList = keysBranchName.map((branch) => (
          { value: branch, label: branch }
        ))
          setListBranchName(branchNameList)
      }
      if (currentStudentInformation.hasOwnProperty('dataCharacter')) {
        !_.isEmpty(currentStudentInformation?.selectedContent) && setSelectedContent(convertAutotickSubject(currentStudentInformation?.selectedContent['共通テスト']))
        !_.isEmpty(currentStudentInformation?.selectedContent) && setSelectedContentPerson(convertAutotickSubject(currentStudentInformation?.selectedContent['二次・個別']))
        // setSelectedContentPerson(arrAutotickContentPersonal)
        // setSelectedContent(arrAutotickContentCommon)
      }
    }
  }, [dataUniversity])
  const clearAllError = () => {
    clearErrors([
      "studentID",
      "studentName",
      "scheduleMethod",
      "universityName",
      "departmentName",
      "majorName",
    ]);
  };

  const handleUpdateCurriculum = () => {
    history.push({
      // pathname: `/result-lesson/${studentID}/${params.curriculum}`,
      pathname: `/student/${studentID}/curriculum/${params.curriculum}/lesson-results`,
      state: { 
        isUpdate: true, 
        socialScience: inforStudent.socialScience
      }
    })
  }

  const handleSearchUniversity = async (e) => {
    if (!_.isEmpty(currentStudentInformation)) {
      dispatch(resetInfomation({
        universityNameNew: e,
        scheduleMethodNew: null,
        facultyNameNew:null,
        majorNameNew: null,
        selectedContent: {}
      }))
      dispatch(listDataUniversity({
        dataUniversitys : {}
      }))
    }
    if (e === undefined) {
      setListFaculty([])
      setFaculty(null)
      setListDepartmentMajor([])
      setDepartmentMajor(null)
      setListScheduleMethod([])
      setScheduleMethod(null)
      setListBranchName([])
      setBranchName(null)
      setListScience([])
      setScience(null)
      clearErrors([
        "facultyNameNew",
        "majorNameNew",
        "scheduleMethodNew",
        "branchAspiration"
      ])
      setUniversity(e)
      setValue("universityNameNew", e);
      setValue("facultyNameNew", e);
      setValue("majorNameNew", e);
      setValue("scheduleMethodNew", e);
      setValue("branchAspiration", e);
      setValue("socialScience", e)
      setAspirationId(undefined)
    } else {
      clearErrors([
        "universityNameNew",
      ])
      setUniversity(e)
      setValue("universityNameNew", e);
      setFaculty(null)
      setScience(null)
      setListDepartmentMajor([])
      setDepartmentMajor(null)
      setListScheduleMethod([])
      setScheduleMethod(null)
      setListBranchName([])
      setBranchName(null)
      setValue("facultyNameNew", undefined);
      setValue("socialScience", undefined);
      setValue("majorNameNew", undefined);
      setValue("scheduleMethodNew", undefined);
      setValue("branchAspiration", undefined);
      setAspirationId(undefined)
    }
  }

  const handleSearchFaculty = (e) => {
    if (!_.isEmpty(currentStudentInformation) && e !== currentStudentInformation.facultyNameNew) {
      dispatch(resetInfomation({
        selectedContent: {}
      }))
    }
    if (e === undefined) {
      setListDepartmentMajor([])
      setDepartmentMajor(null)
      setListScheduleMethod([])
      setScheduleMethod(null)
      setListBranchName([])
      setBranchName(null)
      setFaculty(null)
      clearErrors([
        "majorNameNew",
        "scheduleMethodNew",
        "branchAspiration"
      ])
      setValue("facultyNameNew", e);
      setValue("majorNameNew", e);
      setValue("scheduleMethodNew", e);
      setValue("branchAspiration", e);
      setFaculty(e)
      setAspirationId(undefined)
    } else {
      clearErrors([
        "facultyNameNew",
      ])
      let keys = Object.keys(dataUniversity[`${university}`][`${e}`]);
      const departmentMajor = keys.map((major) => (
        { value: major, label: major }
      ))
      setValue("facultyNameNew", e);
      setFaculty(e)
      setListDepartmentMajor(departmentMajor)
      setListScheduleMethod([])
      setListBranchName([])
      setBranchName(null)
      setValue("majorNameNew", undefined);
      setValue("scheduleMethodNew", undefined);
      setValue("branchAspiration", undefined);
      setDepartmentMajor(null)
      setScheduleMethod(null)
      setAspirationId(undefined)
    }
  }
  const startTimeValue = watch("startTime");
  const endTimeValue = watch("endTime"); 
  const handleSearchMajor = (e) => {
    if (!_.isEmpty(currentStudentInformation) && e !== currentStudentInformation.majorNameNew ) {
      dispatch(resetInfomation({
        selectedContent: {}
      }))
    }
    if (e === undefined) {
      setListScheduleMethod([])
      setScheduleMethod(null)
      setListBranchName([])
      setBranchName(null)
      clearErrors([
        "scheduleMethodNew",
        "branchAspiration"
      ])
      setValue("majorNameNew", e);
      setValue("scheduleMethodNew", e);
      setValue("branchAspiration", e);
      setDepartmentMajor(e)
      setAspirationId(undefined)
    } else {
      clearErrors([
        "majorNameNew",
      ])
      let keys = Object.keys(dataUniversity[`${university}`][`${faculty}`][`${e}`]);
      const scheduleMethod = keys.map((method) => (
        { value: method, label: method }
        ))
      setValue("majorNameNew", e);
      setDepartmentMajor(e)
      setListScheduleMethod(scheduleMethod)
      setScheduleMethod(null)
      setListBranchName([])
      setBranchName(null)
      setValue("scheduleMethodNew", undefined);
      setValue("branchAspiration", undefined);
      setAspirationId(undefined)
    }
  }
  const handleSearchMethod = (e) => {
    if (!_.isEmpty(currentStudentInformation) && e !== currentStudentInformation.scheduleMethodNew) {
      dispatch(resetInfomation({
        selectedContent: {}
      }))
    }
    if (e === undefined) {
      setValue("scheduleMethodNew", e);
      setValue("branchAspiration", e);
      setScheduleMethod(e)
      setListBranchName([])
      setBranchName(null)
      clearErrors([
        "branchAspiration"
      ])
      setAspirationId(undefined)
    } else {
      let keys = Object.values(dataUniversity[`${university}`][`${faculty}`][`${departmentMajor}`][`${e}`]);
      const branchName = keys.map((branch) => (
        { value: branch, label: branch }
      ))
      setListBranchName(branchName)
      setBranchName(null)
      setValue("scheduleMethodNew", e);
      setScheduleMethod(e)
      clearErrors([
        "scheduleMethodNew",
      ])
      setValue("branchAspiration", undefined);
      setAspirationId(undefined)
    }
  }

  const handleSearchBranchName = (e) => {
    if (!_.isEmpty(currentStudentInformation)) {
      dispatch(resetInfomation({
        selectedContent: {}
      }))
    }
    if (e === undefined) {
      setValue("branchAspiration", e);
      setBranchName(null)
    } else {
      setValue("branchAspiration", e);
      setBranchName(e)
      clearErrors([
        "branchAspiration",
      ])
    }
  }

  const handleScience = (e) => {
    if (!_.isEmpty(currentStudentInformation)  && e !== currentStudentInformation.socialScience) {
      dispatch(resetInfomation({
        selectedContent: {}
      }))
    }
    setValue("socialScience", e)
    setScience(e)
    clearErrors([
      "socialScience",
    ])
  }
  const onToggleWeeklyCourse = () => {
    onToggleCharacter()
    setShowWeeklyCourse(!showWeeklyCourse)
  }
  const onToggleCharacter = () => {
    setCharacter(!character)
  }
  const onModalWeeklyCourse = (data) => {
    setDataCharacter(data)
    onToggleCharacter()
    onToggleWeeklyCourse()
  }
  const onModalInforStudent = (data,option) => {
    setRatioTimeunitSubject(data)
    setOptionTimeLimit(option)
    onToggle()
  } 
  const onClearData = () => {
    setUniversity(undefined)
    setFaculty(undefined)
    setDepartmentMajor(undefined)
    setScheduleMethod(undefined)
    setScience(undefined)
    setBranchName(undefined)
    setValue("universityNameNew", undefined);
    setValue("facultyNameNew", undefined);
    setValue("majorNameNew", undefined);
    setValue("scheduleMethodNew", undefined);
    setValue("socialScience", undefined);
    setValue("branchAspiration", undefined);
    setListFaculty([])
    setListDepartmentMajor([])
    setListScheduleMethod([])
    setListBranchName([])
    setTime({
      startTime: moment(new Date()),
      endTime: moment(new Date()).add(1, "years"),
    })
  }

  const handleResetForm = () => {
    if (inforStudent.generatedClassScheduleID === null) {
      window.location.reload(false)
    }
  }

  useEffect(() => {
    if(location?.state?.noUni) {
      handleResetForm()
    }
  }, [])

  const items = [
    {
      label: 'check all'
    },
    {
      key: '1',
      label: <>
        共通テスト
      <Tooltip title={t("tooltip_common_test")}>
        <QuestionCircleOutlined className="icon-question"/>
      </Tooltip>
      </>,
      children: (
        <>
          {
            <ListCourses
              dataSubject={listSubjectContentCommonFirst}
              dataSource={listSubjectContentCommon}
              setValue={setValue}
              setSelectedContent={setSelectedContent}
              checkSocialScience={socialScience}
              setIndexMathChecked={setIndexMathChecked}
              indexMathChecked={indexMathChecked}
              selectedContent={selectedContent}
              validateCheckSocialScience={handleSubmit(onSubmit)}
            />
          }
        </>
      )
    },
    {
      key: '2',
      label: <>
        二次・個別
      <Tooltip title={t("tooltip_secondary_individual")}>
        <QuestionCircleOutlined className="icon-question"/>
      </Tooltip>
      </>,
      children: (
        <>
          {
            <ListCourses
            dataSubject={listSubjectContentPersonalFirst}
              dataSource={listSubjectContentPersonal}
              setValue={setValue}
              setSelectedContent={setSelectedContentPerson}
              setIndexMathChecked={setIndexMathChecked}
              indexMathChecked={indexMathChecked}
              selectedContent={selectedContentPerson}
              isPersonal={true}
              checkSocialScience={socialScience}
              validateCheckSocialScience={handleSubmit(onSubmit)}
            />
          }
        </>
      )
    },
  ];

  const handleSelectAll = () => {
    if (!socialScience) {
      handleSubmit(onSubmit)()
      return
    }
    const selectedContentToUpdate = Number(keyTab) === 1 ? selectedContent : selectedContentPerson;
    const selectedContentKeyScience = selectedContentToUpdate?.['理科'] ? Object.keys(selectedContentToUpdate?.['理科']) : [];
    const selectedContentKeySociety = selectedContentToUpdate?.['社会'] ? Object.keys(selectedContentToUpdate?.['社会']) : [];

    const listSubjectContentToUpdate = Number(keyTab) === 1 ? listSubjectContentCommon : listSubjectContentPersonal;
    
    const converSubjectContent = convertSubjectContentToKeyValueArray(listSubjectContentToUpdate, selectedContentKeyScience, selectedContentKeySociety);
    
    const isAllSelected = flattenArrayFromObject(converSubjectContent).length === flattenArrayFromObject(selectedContentToUpdate).length;
    
    const updatedContent = isAllSelected ? convertValueTickSubjectToArrEmpty(selectedContentToUpdate) : { ...converSubjectContent };
    if (Number(keyTab) === 1) {
      setSelectedContent(updatedContent);
    } else {
      setSelectedContentPerson(updatedContent);
    }
  };
  const renderChecked = () => {
    const contents = Number(keyTab) === 1 ? listSubjectContentCommon : listSubjectContentPersonal;
    const selectedContents = Number(keyTab) === 1 ? selectedContent : selectedContentPerson;
    const getKeyScience = (!_.isEmpty(selectedContents) && selectedContents?.['理科']) && Object.keys(selectedContents?.['理科'])
    const getKeyScociety = (!_.isEmpty(selectedContents) && selectedContents?.['社会']) && Object.keys(selectedContents?.['社会'])
    let converContent = convertSubjectContentToKeyValueArray(contents, getKeyScience, getKeyScociety)
    return flattenArrayFromObject(converContent).length ===  flattenArrayFromObject(selectedContents).length
  }
  const OperationsSlot= {
    left: <div className="checkbox_checkall">
      <input
        type="checkbox"
        className="list-course-checked"
        name={keyTab}
        value={keyTab}
        onChange={handleSelectAll}
        checked={renderChecked()}
      />
      <span>{t('checkbox_select_all')}</span>
    </div>,
  };
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (!/^\d$/.test(e.key) && keyCode !== 8 && keyCode !== 46) {
      e.preventDefault();
    }
  }
  const handleWeekCourse = (e) => {
    if (e.target.value > maxWeeklyCourse) {
      setWeeklyCourse(maxWeeklyCourse)
    }
    else if (e.target.value.length && Number(e.target.value) === 0) {
      setWeeklyCourse(1)
    }
    else setWeeklyCourse(e.target.value)
  }
  
  return (
    !testLoading ? (
      <Wrap>
        
        <div className="header-breadcumb">
            <BreadcrumbComponent className='backHome' icon={<IconBack />} name={t("back")} />
            <div className="infomation">
              <div className="line-left"></div>
              <div className="title-infomation">{t("student_information")}</div>
            </div>
          </div>
        <FormProvider {...form}>
          <form
            // onSubmit={handleSubmit(onSubmit)}
            id="information-form"
            autoComplete="off"
            ref={scrollToForm} 
          >
            <Row gutter={[32]}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("studentID")}
                  name="studentID"
                  type="text"
                  disabled={!!params?.ID}
                  onBlur={(e) => {
                    if (!regex.interger8digit.test(e.target.value)) {
                      return;
                    }
                    onGetInformationStudent(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    if (!regex.interger8digit.test(e.target.value)) {
                      return;
                    }
                    onGetInformationStudent(e.target.value);
                  }}
                  onChange={(e) => {
                    clearAllError();
                    if (e.target.value.length < 8) {
                      setValue("studentID", "");
                      setValue("universityName", "");
                      setValue("departmentName", "");
                      setValue("majorName", "");
                      setIdStudent(true)
                    } else {
                      setIdStudent(false)
                    }
                    if (!regex.interger0to8digit.test(e.target.value)) {
                      return;
                    } else {
                      onClearData()
                      setValue("studentID", e.target.value);
                      setValue("studentName", "");
                      setValue("scheduleMethod", "");
                    }
                  }}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput label={t("student_name")} name="studentName" disabled={true} />
              </Col>
              
                
            </Row>
            
            {isGenerated ? (
              <>
                <Row gutter={[32]}>
                  <Col span={24} lg={12}>
                    <FormInput label={t("university_name")} name="universityName" disabled={true} />
                  </Col>
                  <Col span={24} lg={12}>
                    <FormInput label={t("faculty_name")} name="departmentName" disabled={true} />
                  </Col>
                </Row>

                <Row gutter={[32]}>
                  <Col span={24} lg={12}>
                    <FormInput label={t("department_major")} name="majorName" disabled={true} />
                  </Col>
                  <Col span={24} lg={12}>
                    <FormInput label={t("schedule_method")} name="scheduleMethod" disabled={true} />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[32]} justify="start">
                  {inforStudent?.universityName !== null && inforStudent?.majorName !== null && inforStudent?.departmentName !== null ? (
                    <Col span={24} lg={12} className="wear_info">
                      <TitleInfo>{t("wear_information")}</TitleInfo>
                      <WearInfomation>
                        <FormInput name="universityName" disabled={true} />
                        <FormInput name="departmentName" disabled={true} />
                        <FormInput name="majorName" disabled={true} />
                      </WearInfomation>
                    </Col>
                  ) : (
                    <Col span={24} lg={12} className="wear_info">
                      <TitleInfo>{t("wear_information")}</TitleInfo>
                      <span className="no_information">{t("no_information")}</span>
                    </Col>
                  )}
                </Row>
                  <>
                  <TitleInfo>{t("subject_allocation")}</TitleInfo>
                  <Row gutter={[32]}>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<WrapperLabel>{t("university_name")}</WrapperLabel>}
                        validateStatus={errors.universityNameNew ? 'error' : ''}
                        help={!apiFailed && errors?.universityNameNew?.message}
                      >
                        <Select
                          className="select_type"
                          name="universityNameNew"
                          showSearch
                          value={university || null}
                          // placeholder={t("university_name")}
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          allowClear
                          onChange={handleSearchUniversity}
                          options={listUniversity}
                          disabled={apiFailed}
                          notFoundContent={textNoData}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<WrapperLabel>{t("faculty_name")}</WrapperLabel>}
                        validateStatus={listFaculty.length === 0 || errors.facultyNameNew ? 'error' : ''}
                        help={listFaculty.length === 0 || errors?.facultyNameNew?.message}
                      >
                        <Select
                          className="select_type"
                          showSearch
                          label={t("faculty_name")}
                          value={faculty || null}
                          // name="faculty_name"
                          name="facultyNameNew"
                          options={listFaculty}
                          defaultValue={listFaculty[0]?.value}
                          // placeholder={t("faculty_name")}
                          onChange={handleSearchFaculty}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          disabled={!listFaculty.length}
                          notFoundContent={textNoData}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32]}>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<WrapperLabel>{t("department_major")}</WrapperLabel>}
                        validateStatus={listDepartmentMajor.length === 0 || errors.majorNameNew ? 'error' : ''}
                        help={listDepartmentMajor.length === 0 || errors?.majorNameNew?.message}
                      >
                        <Select
                          className="select_type"
                          showSearch
                          label={t("department_major")}
                          name="majorNameNew"
                          value={departmentMajor || null}
                          options={listDepartmentMajor}
                          defaultValue={listDepartmentMajor[0]?.value}
                          // placeholder={t("department_major")}
                          allowClear
                          onChange={handleSearchMajor}
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          disabled={!listDepartmentMajor.length}
                          notFoundContent={textNoData}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<WrapperLabel>{t("schedule_method")}</WrapperLabel>}
                        validateStatus={listScheduleMethod.length === 0 || errors.scheduleMethodNew ? 'error' : ''}
                        help={listScheduleMethod.length === 0 || errors?.scheduleMethodNew?.message}
                      >
                        <Select
                          className="select_type"
                          showSearch
                          label={t("schedule_method")}
                          name="scheduleMethodNew"
                          value={scheduleMethod || null}
                          options={listScheduleMethod}
                          defaultValue={listScheduleMethod[0]?.value}
                          // placeholder={t("schedule_method")}
                          allowClear
                          onChange={handleSearchMethod}
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          disabled={!listScheduleMethod.length}
                          notFoundContent={textNoData}
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                </>          
              </> 
            )}

            <Row gutter={[32]}>
              {isGenerated ? (
                <Col span={24} lg={12}>
                  <FormInput label={t("branch_name")} name="branchAspiration" disabled={true} placeholder={`${t("no_branch")}`}/>
                </Col>
              ) : (
                <Col span={24} lg={12}>
                  <Form.Item
                    label={<WrapperLabel>{t("branch_name")}</WrapperLabel>}
                    validateStatus={listBranchName.length === 0 || errors.branchAspiration ? 'error' : ''}
                    help={listBranchName.length === 0 || errors?.branchAspiration?.message}
                  >
                    <Select
                      className="select_type"
                      showSearch
                      label={t("branch_name")}
                      name="branchAspiration"
                      value={branchName || null}
                      options={listBranchName}
                      defaultValue={listBranchName[0]?.value}
                      placeholder={!listBranchName.length && scheduleMethod && `${t("no_branch")}`}
                      // allowClear
                      onChange={handleSearchBranchName}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      disabled={!listBranchName.length}
                      notFoundContent={textNoData}
                    />
                  </Form.Item>
                </Col>
              )}            

              <Col span={24} lg={12}>
                <DateTimeTitle span={24}>
                  <span>{t("course_period")}</span>
                </DateTimeTitle>
                <Row>
                  <Col span={11} id='dropdown-year'>
                    <FormDatePicker
                      className="time-find-course"
                      label=""
                      name="startTime"
                      rules=""
                      setTime={setTime}
                      time={time}
                      disabled={studentIDSaved}
                      defaultValue={startTimeValue}
                      getPopupContainer={() =>
                        document.getElementById("dropdown-year")
                      }
                    />
                  </Col>
                  <MidleDateTime span={2}>~</MidleDateTime>
                  <Col span={11}>
                    <FormDatePicker
                      className="time-find-course"
                      label=""
                      name="endTime"
                      rules=""
                      setTime={setTime}
                      time={time}
                      disabled={studentIDSaved}
                      defaultValue={endTimeValue}
                    />
                  </Col>
                  </Row>
              </Col>
            </Row>
            <Row gutter={[32]}>
              {isGenerated ? (
                <Col span={24} lg={12}>
                  <FormInput label={t("social_science")} name="scienceType" disabled={true} />
                </Col>
              ) : (
                <Col span={24} lg={12}>
                  <Form.Item
                    label={<WrapperLabel>{t("social_science")}</WrapperLabel>}
                    validateStatus={errors.socialScience ? 'error' : ''}
                    help={!apiFailed && errors?.socialScience?.message}
                  >
                    <Select
                        className="select_type"
                        name="socialScience"
                        showSearch
                        value={science || null}
                        placeholder={t("title_science")}
                        optionFilterProp="children"
                        defaultValue={listScience[0]?.value}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        onChange={handleScience}
                        options={listScience}
                        disabled={apiFailed}
                        notFoundContent={textNoData}
                      />
                  </Form.Item>
                </Col>
              )}
              <Col span={24} lg={12}>
                {isGenerated ?
                  <FormInput
                    name="limitPeriod"
                    label={t("weekly_course")}
                    disabled={isGenerated}
                  /> : 
                  <FormInput
                    name="limitPeriod"
                    type="number"
                    min={1}
                    max={maxWeeklyCourse}
                    onKeyDown={handleKeyDown}
                    label={t("weekly_course")}
                    value={weeklyCourse}
                    onChange={(e) => handleWeekCourse(e)}
                  />
                  }
              </Col>
            </Row>
            {
              (!_.isEmpty(maxScore) && university && faculty && departmentMajor && scheduleMethod) && ((listBranchName.length === 0 && aspirationId) || (listBranchName.length > 0 && aspirationId && branchName !== null)) ? (
                <>
                  <TableComponent
                    columns={dataGeneralExam?.nameCourse}
                    dataSource={dataGeneralExam?.valueCourse}
                    titleTable={t("common_test")}
                  />

                  <TableComponent
                    columns={dataIndividualExam?.nameCourse}
                    dataSource={dataIndividualExam?.valueCourse}
                    titleTable={t("secondary_individual")}
                  />
                </>
              ) : !_.isEmpty(maxScore) && isGenerated ? (
                <>
                  <TableComponent
                    columns={dataGeneralExam?.nameCourse}
                    dataSource={dataGeneralExam?.valueCourse}
                    titleTable={t("common_test")}
                  />

                  <TableComponent
                    columns={dataIndividualExam?.nameCourse}
                    dataSource={dataIndividualExam?.valueCourse}
                    titleTable={t("secondary_individual")}
                  />
                </>
              ) : (_.isEmpty(maxScore) && university && faculty && departmentMajor && scheduleMethod) || (_.isEmpty(maxScore) && isGenerated) ? (
                <>
                  <TableComponent
                    columns={[]}
                    dataSource={[]}
                    titleTable={t("common_test")}
                  />

                  <TableComponent
                    columns={[]}
                    dataSource={[]}
                    titleTable={t("secondary_individual")}
                  />
                </>
              ) : (
                <></>
              )
            }

            {studentIDSaved && contentSubjectIDSaved && isGenerated ? (
              <ListCourseChecked
                loading={loadingstudentIDSaved}
                dataSource={contentSubjectIDSaved && contentSubjectIDSaved["共通テスト"]}
                dataselectedContentPerson={contentSubjectIDSaved && contentSubjectIDSaved["二次・個別"]}
              /> 
            ) : (
              <Tabs 
                defaultActiveKey="1" 
                items={items} 
                tabBarExtraContent={OperationsSlot}
                onChange={(e)=>setKeyTab(e)}
              />
            )}
           <ModalCharacterStudent
              visibleModal={character}
              onOk={onModalWeeklyCourse}
              onCancel={onToggleCharacter}
              isGenCharacter={checkGenerated}
            />
            {showWeeklyCourse && <ModalWeeklyCourse
              visibleModal={showWeeklyCourse}
              onOk={onModalInforStudent}
              onCancel={onToggleWeeklyCourse}
              listSubject={getListSubjectTick}
              weeklyCourseMax={weeklyCourse}
            // isGenCharacter={checkGenerated}
            />}
            {visibleModal && <ModalInforStudent
              visibleModal={visibleModal}
              onOk={onToggle}
              onCancel={onToggle}
              dataGeneralExam={dataGeneralExam}
              dataIndividualExam={dataIndividualExam}
              inforStudent={dataSelected}
              aspirationId={aspirationId}
              dataCharacter={dataCharacter}
              ratioTimeunitSubject={ratioTimeunitSubject}
              optionTimeLimit={optionTimeLimit}
              weeklyCourse={weeklyCourse}
              //get env if IS_EXPORT_STEP1 == true && has student save get content student save 
              selectedContent={IS_EXPORT_STEP1 && studentIDSaved ? contentSubjectIDSaved : selectedContent}
              selectedContentPerson={IS_EXPORT_STEP1 && studentIDSaved ? contentSubjectIDSaved : selectedContentPerson}
            // time={time}
            />}
           
          </form>
        </FormProvider>
        <Row justify={"space-around"}>
          <Col span={24} sm={12}>
            {/* disable when IS_EXPORT_STEP1 == true */}
            <FailureBtn disabled={!studentIDSaved || loadingstudentIDSaved || !studentIDInput || IS_EXPORT_STEP1} loading={loadingstudentIDSaved} onClick={handleUpdateCurriculum}>{t("update_existing_curriculum")}</FailureBtn>
          </Col>
          <ColWraper span={24} sm={12}>
            {/* <SuccessBtn onClick={onReset}>リセット</SuccessBtn>
            <div style={{ width: "2rem" }}></div> */}
            {/* enable when IS_EXPORT_STEP1 == true */}
            <FailureBtn onClick={handleSubmit(onSubmit)} loading={loading} disabled={(loading || studentIDSaved || idStudent) && !IS_EXPORT_STEP1 }>{t("aptitude")} </FailureBtn>
          </ColWraper>
        </Row>
        {loadingGetFullUni && <LoadingModal />}
      </Wrap>
    ) : (
      <WrapperSpin>
        <Spin size="large" />
        </WrapperSpin>
        
    )
  );
};

export default Home;
